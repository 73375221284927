/**
 *
 * RegistrationModal
 *
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Modal from 'components/modals/index';
import Login from 'containers/Auth/Login';
import Registration from 'containers/Registration';

import { sendCustomGaEvent } from 'utils/ga';

import {
  closeRegistrationModal,
  openLoginModal,
  openSignupModal,
  openForgotPasswordModal,
  openResetPasswordModal,
} from 'actions/modals';

import useRouter from 'utils/hooks/useRouter';
import usePrevious from 'utils/hooks/usePrevious';
import {
  SIGNUP_ROUTE,
  LOGIN_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  RESET_PASSWORD_ROUTE,
} from 'utils/routes';

let tempData = {
  signup: {},
  login: {},
};
const getTempData = () => tempData;
const setTempData = (form, data) => {
  tempData = {
    ...tempData,
    [form]: {
      ...tempData[form],
      ...data,
    },
  };
};

const RegistrationModal = ({ type, modalPathname, modal, dispatch }) => {
  const { location } = useRouter();
  const prevPathname = usePrevious(location.pathname);

  const isOpen = modal.registrationType === type && modal.isOpen;

  const onRequestClose = () => {
    dispatch(closeRegistrationModal());
    sendCustomGaEvent('Sign Up', 'Dropoff', 'Signup Exited');
  };

  const handleRedirect = ROUTE => evt => {
    evt.preventDefault();

    if (ROUTE === FORGOT_PASSWORD_ROUTE && location.pathname === SIGNUP_ROUTE) {
      dispatch(closeRegistrationModal());
      dispatch(openForgotPasswordModal());
      // dirty trick to track if coming from modal or not
      window.prevIsModal = true;
    } else if (prevPathname.includes(ROUTE)) {
      // if the prev path is the same as the new requested modal,
      // just close to reveal page --> prevents login modal from opening on top of login page etc
      onRequestClose();
      // dirty trick to track if coming from modal or not
      window.prevIsModal = false;
    } else if (
      // location.pathname !== '/signup' &&
      !prevPathname.includes(SIGNUP_ROUTE) &&
      !prevPathname.includes(openSignupModal)
    ) {
      // if the prev path was not either login or signup,
      // then open the other modal
      dispatch(closeRegistrationModal());
      switch (ROUTE) {
        case LOGIN_ROUTE:
          dispatch(openLoginModal()); // this will inadvertantly close other modal
          sendCustomGaEvent('Log In', 'Started', 'Login Began');
          break;
        case SIGNUP_ROUTE:
          dispatch(openSignupModal());
          sendCustomGaEvent('Sign Up', 'Started', 'Signup Began');
          break;
        case FORGOT_PASSWORD_ROUTE:
          dispatch(openForgotPasswordModal());
          break;
        case RESET_PASSWORD_ROUTE:
          dispatch(openResetPasswordModal());
          break;
        default:
      }
      // dirty trick to track if coming from modal or not
      window.prevIsModal = true;
    }
  };

  const IS_LOGIN = type === 'login';
  const props = IS_LOGIN ? { maxWidth: '500px' } : {};

  return (
    <Modal
      cover
      noMaxHeight
      closeOnOutsideClick
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      // width={IS_LOGIN ? 'calc(90% - 30px)' : 'calc(100% - 30px)'}
      width="calc(100% - 30px)"
      title={IS_LOGIN ? 'Sign In' : 'Signup'}
      {...props}
    >
      {IS_LOGIN ? (
        <Login
          isModal
          handleRedirect={handleRedirect}
          closeModal={onRequestClose}
        />
      ) : (
        <Registration
          isModal
          handleRedirect={handleRedirect(LOGIN_ROUTE)}
          closeModal={onRequestClose}
          setTempData={setTempData}
          getTempData={getTempData}
        />
      )}
    </Modal>
  );
};

RegistrationModal.propTypes = {
  modal: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  modalPathname: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = ({ display, location }) => ({ display, location });

export default connect(mapStateToProps)(RegistrationModal);
