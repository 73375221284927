/**
 *
 * RipspotCreation to S3 CONTAINER
 *
 *
 */

import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useTransition, animated } from 'react-spring';

import usePaymentTypeLogic from 'components/ReviewRipspot/usePaymentTypeLogic';

import {
  setTempRipspot,
  setIsInExistingPinEditMode,
  // setEditInProgress,
  setIsInNewRipspotMode,
  submitToggleRipspotCreation,
  setEditInProgress,
  setRipspotLoading,
  removeCurrentRipspot,
  setViewSingleRipspot,
} from 'actions/ripspots';

import {
  openAdditionalPinModal,
  closeAdditionalPinModal,
  closeRipspotModal,
  setRipspotCurrentStep,
  setRipspotPrevStep,
} from 'actions/modals';

import {
  // toastSuccess,
  toastError,
  toastInfo,
  toastUpdateError,
  // toastUpdate,
} from 'utils/toastify';

import { setIsPaying } from 'actions/checkout';

import Spinner from 'components/Spinner';
import Button from 'components/Elements/Button';

import usePrevious from 'utils/hooks/usePrevious';
import useOnChangeWatcher from 'utils/hooks/useOnChangeWatcher';
import Theme from 'utils/theme';
import {
  PHOTO_GALLERY,
  DETAILS,
  BIO,
  PAYMENT,
  REVIEW,
  SHARE,
} from 'utils/constants';
import {
  MAP_CR_LOCATIONS_ROUTE,
  MAP_CR_DETAILS_ROUTE,
  MAP_CR_BIO_ROUTE,
  MAP_CR_PHOTOS_ROUTE,
  MAP_CR_REVIEW_ROUTE,
  MAP_CR_PAYMENT_ROUTE,
  MAP_CR_CONFIRMATION_ROUTE,
} from 'utils/routes';

import PhotoUploads from './PhotoUploads';
import Details from './Details';
import Bio from './Details/Bio';
import Payment from './Payment';
import Review from './Review';
import {
  completeRipspotHandler,
  updateRipspotHandler,
} from './Review/reviewHandler';
import Share from './Share';
// import { photoGalleryData } from './utils';

// works!!!
import { steps, editSteps, stepNumByKey, editStepNumByKey } from './steps';

const { colors } = Theme;
const isDev = false;

// step 1: Bio
// step 2: Detail info
// step 3: Photo gallery uploads (S3)
// step 4: Order Confirmation / Review Order
// step 5: Payment View
// step 6: Share  // TODO: we need this step implemented

// clean way of maintaing state outside of render cycle
// to persist across form navigations
export const initData = {
  details: {
    name: isDev ? 'Blank Person' : '',
    birthDate: {
      day: isDev ? 10 : null,
      month: isDev ? 31 : null,
      year: isDev ? 1900 : null,
    },
    deathDate: {
      day: null,
      month: null,
      year: null,
    },
    isArmedServices: false,
    branch: '',
  },
  bio: {
    text: '',
  },
  payment: {
    plan: 'annual', // annual || one-time
  },
  photoGallery: {}, // isDev ? { ...photoGalleryData, ...photoGalleryData } : {},
  review: { visibility: 'public' },
  share: {},
  pins: {},
};

let tempData = { ...initData };

const getTempData = () => tempData;
const setTempData = (field, data) => {
  tempData[field] = data;
};

//
// used now for storing changes to photoGallery
//
const initEditData = {
  photoGallery: {
    deleted: [], // [imageId]
    edited: {}, // {  [imageId]: updateData }
    // added: {} // TODO: might need to implement this
  },
};

let tempEditData = { ...initEditData };

const getTempEditData = () => tempEditData;
const setTempEditData = (field, data) => {
  tempEditData[field] = data;
};

const resetTempEditData = () => {
  tempEditData = { ...initEditData };
};

const editTempDataProps = {
  getTempEditData,
  setTempEditData,
  resetTempEditData,
};
//
//
//

//
// Reset ALL temp data
//
const resetTempData = () => {
  tempData = { ...initData };

  // now handle edit changes to photoGallery
  resetTempEditData();
};

const PAYMENT_STEP = 5;

/**
 * Root of Ripspot Creation Forms
 *
 * @param {*} { metadata }
 * @returns
 */
const RipspotCreation = ({
  user,
  ripspots,
  metadata,
  dispatch,
  tempPaymentInfo,
  isPaying,
  currentStep,
  setParentStepsToUse,
  shouldClearTempData = false,
  setShouldClearTempData,
  isTouchDevice,
}) => {
  // ripspots shape from redux
  const { editInProgress, currentRipspot, submitToggle } = ripspots;

  const isInNewRipspotMode =
    metadata.isInNewRipspotMode || ripspots.isInNewRipspotMode;

  // allows easy manipulation of order of steps and removal/addition
  // Aaron:: isEditMode doesn't mean the ripspot is purchased and completed.
  const isEditMode = !isInNewRipspotMode;
  const isCompletedRipspot = currentRipspot && currentRipspot.isComplete;
  const stepsToUse = isCompletedRipspot ? editSteps : steps;

  const animatedRef = React.createRef();

  const total = 39;

  useEffect(() => {
    if (isInNewRipspotMode) {
      resetTempData();
    }

    if (Object.keys(ripspots.tempData).length) {
      // eslint-disable-next-line prefer-destructuring
      tempData = ripspots.tempData;
    }
  }, []);

  const [shareViewData, setShareViewData] = useState({});
  // const [submitToggle, setSubmitToggle] = useState(false);

  // ID used to create the share ripspot url in Share step after we get a successful response from api
  const [ripspotID, setRipspotID] = useState('');

  const [disableContinue, setParentDisableContinue] = useState(false); // prevents user from navigating to next step
  const [completePurchase, setCompletePurchase] = useState(isCompletedRipspot);

  const previousStep = usePrevious(currentStep);

  const [orderDetails, setOrderDetails] = useState({});

  const currentStepKey =
    stepsToUse[currentStep] &&
    stepsToUse[currentStep].key &&
    stepsToUse[currentStep].key;

  const setDisableContinue = bool => {
    setParentDisableContinue(bool);
  };

  const resetSteps = () => {
    dispatch(setRipspotCurrentStep(1));
  };

  // useEffect(() => {
  //
  //
  //   if (editInProgress && !currentRipspot) {
  //
  //     resetSteps();
  //   }
  // }, [editInProgress]);

  useEffect(() => {
    // ensures data is cleared after successful ripspot creation

    if (shouldClearTempData) {
      resetTempData();
      resetSteps(); // reset step to step 1 for next ripspot since comp instance stays within modal
    }
  }, [shouldClearTempData]);

  useEffect(() => {
    if (previousStep) {
      dispatch(setRipspotPrevStep(previousStep));
    }
    if (stepsToUse) {
      setParentStepsToUse(stepsToUse);
    }
  }, [stepsToUse, previousStep]);

  useEffect(() => {
    // if user already gave payment info, allow navigation to next screen
    if (
      currentStepKey &&
      currentStepKey === PAYMENT &&
      (tempPaymentInfo === '' || tempPaymentInfo.tokenId)
    ) {
      setDisableContinue(false); // allow user to continue
    }

    if (currentStepKey && currentStepKey === REVIEW) {
      // save any data about current ripspot for the share view
      // anything needed for the ripspot url (name/id?)
      setShareViewData(tempData);
    }
  }, [currentStepKey]);

  // eslint-disable-next-line no-use-before-define
  const transitions = useTitleTransition({ currentStep, previousStep }); // defined below

  /**
   * Prev Click
   *
   */
  const handlePreviousClick = () => {
    if (isCompletedRipspot) {
      if (currentStep - 1 === editStepNumByKey.PAYMENT_STEP) {
        dispatch(setRipspotCurrentStep(currentStep - 2));
        animatedRef.current.scrollTop = 0;
      } else {
        if (currentStep - 1 === 0) {
          dispatch(closeRipspotModal());
          navigate(MAP_CR_LOCATIONS_ROUTE);
        } else {
          dispatch(setRipspotCurrentStep(currentStep - 1));
          updateRoute(currentStep - 1);
          animatedRef.current.scrollTop = 0;
        }
      }
    } else {
      if (currentStep - 1 === 0) {
        dispatch(closeRipspotModal());
        if (isTouchDevice) {
          dispatch(openAdditionalPinModal());
        }
        navigate(MAP_CR_LOCATIONS_ROUTE);
      } else {
        updateRoute(currentStep - 1);
        dispatch(setRipspotCurrentStep(currentStep - 1));
      }
      animatedRef.current.scrollTop = 0;
    }
    dispatch(setTempRipspot(tempData));
  };

  /**
   * Handle updating routes
   */
  const updateRoute = nextStep => {
    if (nextStep === 1) {
      navigate(MAP_CR_DETAILS_ROUTE);
    } else if (nextStep === 2) {
      navigate(MAP_CR_BIO_ROUTE);
    } else if (nextStep === 3) {
      navigate(MAP_CR_PHOTOS_ROUTE);
    } else if (nextStep === 4) {
      navigate(MAP_CR_REVIEW_ROUTE);
    } else if (nextStep === 5) {
      navigate(MAP_CR_PAYMENT_ROUTE);
    } else if (nextStep === 6) {
      navigate(MAP_CR_CONFIRMATION_ROUTE);
    }
  };

  /**
   * Continue Click
   *
   */
  const handleContinueClick = () => {
    if (!isEditMode || !currentRipspot) {
      return;
    }

    if (isCompletedRipspot) {
      if (currentStep + 1 === editStepNumByKey.PAYMENT_STEP) {
        //
        // shouldnt be able to get here.
        //
        // double check, no paymwent in edit mode just "update ripspot"
        // payment -- SKIP this one
        // setCurrentStep(currentStep + 2);
        // toastInfo(`Payment step ${PAYMENT_STEP}!!!`);
      } else {
        const shouldUpload = currentStep + 1 === editStepNumByKey.SHARE_STEP;

        dispatch(submitToggleRipspotCreation());
        if (shouldUpload) {
          dispatch(closeRipspotModal());

          navigate('/account');
          setTimeout(() => {
            dispatch(setEditInProgress(false));
            dispatch(setIsInNewRipspotMode(false));
            dispatch(setViewSingleRipspot(false));
            dispatch(removeCurrentRipspot());
            resetTempData();
            resetSteps();
          }, 500);
        } else {
          dispatch(setRipspotCurrentStep(currentStep + 1));
          animatedRef.current.scrollTop = 0;
        }
      }
    } else {
      dispatch(submitToggleRipspotCreation());
      const nextStep = currentStep + 1;
      updateRoute(nextStep);
      dispatch(setRipspotCurrentStep(nextStep));
      animatedRef.current.scrollTop = 0;
    }

    dispatch(setTempRipspot(tempData));
  };

  const handlePaymentSaveAndContinue = () => {
    if (tempPaymentInfo.tokenId) {
      updateRoute(currentStep + 1);
      dispatch(setRipspotCurrentStep(currentStep + 1));
      animatedRef.current.scrollTop = 0;
    } else {
      setCompletePurchase(true); // this sets a flag to submit Stripe form
      dispatch(setIsPaying(true));
    }
  };

  const tempDataProps = { getTempData, setTempData };

  const showContinueButton =
    currentStepKey !== REVIEW && currentStepKey !== PAYMENT;

  const disableContinueButton =
    currentStepKey !== PHOTO_GALLERY &&
    currentStepKey !== BIO &&
    disableContinue;

  const isPayment = currentStepKey === PAYMENT;
  const isReview = currentStepKey === REVIEW;
  const isShare = currentStepKey === SHARE;

  //
  //
  //
  useOnChangeWatcher(isPayment, () => {
    //
    // this ensures we reset payment trigger when navigating to this step
    //
    // TODO: double check though for data before - might want to go back a step and come back and pay with same info entered already etc.
    //
    if (isPayment) {
      setDisableContinue(true);
    }
  });

  //
  // order here matters, this overrides above for re-navigation
  //
  useOnChangeWatcher(isReview, () => {
    // this fixes bug where above resets when we leave the payment step and go backwards
    // has to come after isPayment
    if (isReview) {
      setDisableContinue(false);
    }
  });

  /**
   * handleCompleteRipspot
   *
   * @param {*} evt
   */
  const handleCompleteRipspot = async evt => {
    const toastId = toastInfo('Updating ripspot...', {
      autoClose: false,
    });

    const _tempData = getTempData();

    const { latitude, longitude } = metadata.mapState.viewport;
    const ripspotData = {
      ..._tempData,
      latitude,
      longitude,
      protectedPassword: '', // TODO
    };

    try {
      dispatch(setRipspotLoading(true));
      // setLoading(true);
      const createResponse = await completeRipspotHandler({
        dispatch,
        resetTempData,
        ripspotData,
        user,
        ripspots,
        tempPaymentInfo,
        toastId,
      });
      dispatch(setRipspotLoading(false));
      // setLoading(false);

      if (createResponse) {
        setRipspotID(createResponse.id);
        // go to Share step!
        updateRoute(6);
        dispatch(setRipspotCurrentStep(6));
        // tell map we are done creating/editing
        dispatch(setEditInProgress(false));
      }
    } catch (error) {
      dispatch(setRipspotLoading(false));
      // setLoading(false);

      console.error(error);
      toastUpdateError(toastId, error.reason || 'Sorry, something went wrong');
    }
  };

  /**
   * handleUpdateRipspot
   *
   * @param {*} evt
   */
  const handleUpdateRipspot = async () => {
    //
    //
    //
    const toastId = toastInfo('Updating ripspot...', {
      autoClose: true,
    });

    const _tempData = getTempData();

    // const { latitude, longitude } = metadata.mapState.viewport;
    const ripspotData = {
      ..._tempData,
      //latitude,
      //longitude,
      protectedPassword: '', // TODO
    };

    try {
      dispatch(setRipspotLoading(true));
      // setLoading(true);
      const updateResponse = await updateRipspotHandler({
        dispatch,
        ripspotData,
        user,
        ripspots,
        toastId,
      });
      dispatch(setRipspotLoading(false));
      // setLoading(false);

      if (updateResponse) {
        setRipspotID(updateResponse.id);
        // go to Share step!
        //setCurrentStep(editStepNumByKey.SHARE_STEP);
        // tell map we are done creating/editing
        //dispatch(setEditInProgress(false));
      }
    } catch (error) {
      dispatch(setRipspotLoading(false));
      // setLoading(false);

      console.error(error);
      toastUpdateError(toastId, error.reason || 'Sorry, something went wrong');
    }
  };

  //
  // This listens to submit action to fire correct handler function
  //
  useOnChangeWatcher(submitToggle, () => {
    /**
     * Aaron::
     * - We don't need to conside new ripspot here because we create
     *    new ripspot when user place a new pin on map.
     */
    if (submitToggle && isEditMode) {
      if (!isCompletedRipspot && isPaying) {
        handleCompleteRipspot();
      } else {
        handleUpdateRipspot();
      }
    }
  });

  return (
    <Container>
      {/* <Spinner
        loading={ripspots.ripspotIsLoading}
        color={colors.Primary}
        cover
      /> */}
      <Content>
        {transitions.map(({ item, props, key }) => (
          <AnimatedDiv key={key} style={props} ref={animatedRef}>
            {(() => {
              switch (stepsToUse[item].key) {
                case PHOTO_GALLERY:
                  return (
                    <PhotoUploads
                      {...tempDataProps}
                      {...editTempDataProps}
                      editInProgress={editInProgress}
                    />
                  );
                case DETAILS:
                  return (
                    <Details
                      {...tempDataProps}
                      dispatch={dispatch}
                      setDisableContinue={setDisableContinue}
                    />
                  );
                case BIO:
                  return (
                    <Bio
                      {...tempDataProps}
                      setDisableContinue={setDisableContinue}
                    />
                  );

                case REVIEW:
                  return <Review {...tempDataProps} />;
                case PAYMENT:
                  return (
                    <Payment
                      {...tempDataProps}
                      setDisableContinue={setDisableContinue}
                      setCompletePurchase={setCompletePurchase}
                      completePurchase={completePurchase}
                      setOrderDetails={setOrderDetails}
                    />
                  );
                case SHARE:
                  return (
                    <Share
                      ripspotID={ripspotID}
                      tempDataProps={tempDataProps}
                      shareViewData={shareViewData}
                      orderDetails={orderDetails}
                      viewMapClick={evt => {
                        dispatch(closeRipspotModal());
                      }}
                    />
                  );
                default:
                  return null;
              }
            })()}
          </AnimatedDiv>
        ))}
      </Content>

      <Controls isReview={isReview} isPayment={isPayment}>
        {isPayment && (
          <Total>
            <span>Total: </span>$39{' '}
            <span className={'fine'}>(One-Time Fee)</span>
          </Total>
        )}
        {!isShare && (
          <Button onClick={handlePreviousClick}>
            {(() => {
              if (isReview) {
                return 'Back To Edit';
              } else if (currentStep == 1) {
                return 'Add More Pins';
              } else {
                return 'Previous';
              }
            })()}
          </Button>
        )}
        {showContinueButton && !isShare && (
          <Button
            light
            disabled={disableContinueButton}
            onClick={handleContinueClick}
          >
            Save &amp; Continue
          </Button>
        )}
        {isPayment && (
          <Button
            primary
            disabled={disableContinue} // || !tempPaymentInfo}
            onClick={handlePaymentSaveAndContinue}
          >
            Publish Ripspot
          </Button>
        )}
        {isReview && (
          <Button
            light
            disabled={disableContinue}
            onClick={handleContinueClick}
            // onClick={() => setSubmitToggle(v => !v)}
          >
            {isCompletedRipspot ? 'Finish Editing' : 'Proceed to Payment'}
          </Button>
        )}
      </Controls>
    </Container>
  );
};

RipspotCreation.propTypes = {
  dispatch: PropTypes.func.isRequired,
  setParentStepsToUse: PropTypes.func.isRequired,
  //
  metadata: PropTypes.object,
  ripspots: PropTypes.any,
  tempPaymentInfo: PropTypes.any,
  shouldClearTempData: PropTypes.bool,
  isTouchDevice: PropTypes.bool,
};

const mapStateToProps = ({ modals, ripspots, checkout, user, display }) => ({
  user,
  ripspots,
  tempPaymentInfo: checkout.tempPaymentInfo,
  isTouchDevice: display.isTouchDevice,
  currentStep: modals.ripspotModal.currentStep,
  isPaying: checkout.isPaying,
});
const withConnect = connect(mapStateToProps);
export default compose(withConnect)(RipspotCreation);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const AnimatedDiv = styled(animated.div)`
  width: 100%;
  height: 100%;
  padding-top: 15px;
  padding: 15px;
  margin: 0 auto;
`;

const Content = styled.div`
  ${({ theme }) => theme.styles.middle}
  position: relative;
  flex: 1;
  padding: 10px;
  overflow-x: hidden;
  overflow-y: auto;
`;

const Controls = styled.div`
  ${({ isReview }) =>
    isReview
      ? css`
          background-color: ${({ theme }) => theme.colors.Primary};
        `
      : css`
          background: linear-gradient(
            to top,
            rgba(255, 255, 255, 1),
            rgba(255, 255, 255, 1),
            rgba(255, 255, 255, 0.5),
            rgba(255, 255, 255, 0.1)
          );
        `}
  display: flex;
  justify-content: space-between;
  padding: 15px;
  z-index: 1;
  flex-wrap: wrap;
  ${({ isPayment }) =>
    isPayment &&
    css`
      box-shadow: 0px -4px 5px 0px rgba(0, 0, 0, 0.05);
    `}
  button {
    font-size: 0.75rem;
    flex: 1;
    ${({ theme }) => theme.media.min.mobile`
      font-size: 1rem;
    `}
    &:first-of-type {
      margin-right: 10px;
    }
  }
`;

/**
 * react-spring useTransition animation
 *
 * @param {*} { currentStep, previousStep }
 * @returns
 */
function useTitleTransition({ currentStep, previousStep }) {
  const transitions = useTransition(currentStep, key => key, {
    from: item => ({
      position: 'absolute',
      opacity: 0,
      transform: `translate3d(${item < previousStep ? '-' : ''}30px,0,0)`,
    }),
    enter: { opacity: 1, transform: 'translate3d(0px,0,0)' },
    leave: prevItem => ({
      opacity: 0,
      transform: `translate3d(${prevItem < currentStep ? '-' : ''}30px,0,0)`,
    }),
  });

  return transitions;
}

const Total = styled.div`
  margin-top: 7px 0px;
  font-size: 1.6rem;
  margin-bottom: 10px;
  width: 100%;
  span {
    font-weight: bolder;
    &.fine {
      font-size: 0.6em;
      font-weight: 400;
      font-style: italic;
    }
  }
`;
