import { apiGET, apiPOST, apiDELETE } from '../index';
import { COMMENTS } from '../urls';

export const getComments = ripspotId =>
  new Promise((resolve, reject) => {
    apiGET({ url: `${COMMENTS}/${ripspotId}` })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });

export const createComment = (data = {}, recaptchaToken) =>
  new Promise((resolve, reject) => {
    apiPOST({ url: COMMENTS, data, recaptchaToken, useAuth: false })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });

export const deleteComment = commentId =>
  new Promise((resolve, reject) => {
    apiDELETE({ url: `${COMMENTS}/${commentId}` })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
