/**
 *
 * Payment
 *
 *
 */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// import Stripe from 'components/lib/Stripe';
// import StripeV2 from 'components/lib/Stripe/V2';
import StripeV3 from 'components/lib/Stripe/V3';
import Spinner from 'components/Spinner';
import PriceBlock from 'components/PriceBlock';
import ToggleButton from 'components/ToggleButton';
import usePaymentTypeLogic from 'components/ReviewRipspot/usePaymentTypeLogic';

import { setIsProcessing, setTempPaymentInfo } from 'actions/checkout';
import { setStripeInstance } from 'actions/stripe';
import { submitToggleRipspotCreation } from 'actions/ripspots';

/**
 * Payment step of create ripspot flow - first payment step
 *
 * @param {*} { setTempData }
 * @param {*} { getTempData }
 * @param {*} { setDisableContinue }
 * @param {*} { completePurchase }
 * @param {*} { setCompletePurchase }
 * @param {*} { checkout }
 * @param {*} { dispatch }
 * @param {*} { stripe }
 *
 * @returns
 */
const Payment = ({
  setTempData,
  getTempData,
  setDisableContinue,
  completePurchase,
  setCompletePurchase,
  checkout,
  dispatch,
  stripe,
  ripspotIsLoading,
  setOrderDetails,
}) => {
  const tempData = getTempData();
  const { plan } = tempData.payment;

  const { isAnnual, setIsAnnual, total } = usePaymentTypeLogic({
    setTempData,
    plan,
  });

  const [errorMessage, setErrorMessage] = useState('');

  const titleRef = React.createRef();
  const errorRef = React.createRef();

  useEffect(() => {
    setDisableContinue(false);
    titleRef.current.scrollIntoView();
  }, []);

  const scrollErrorIntoView = () => {
    // errorRef.current.scrollIntoView();
  };

  return (
    <Container>
      {/* <Spinner loading={checkout.isPaying || ripspotIsLoading} /> */}
      <PriceWrapper>
        <Title ref={titleRef}>Enter your payment details below.</Title>
        <p>
          You’ll be able to edit this Ripspot at any time. If you have a coupon code, enter it in the field below.
        </p>
        {/* <StyledPriceBlock noMargin fontSizeOr="0.7rem" isAnnual={isAnnual} /> */}
        {/* <p>
          <strong>Please enter your payment info below:</strong>
        </p> */}
        <StripeV3
          setDisableContinue={setDisableContinue}
          completePurchase={completePurchase}
          onHandlePayment={(paymentMethod, token, couponCode, stripe) => {
            //
            //
            // TODO: Make call to API to create the payment or subscription
            //
            //
            const tokenDataToUse = isAnnual ? paymentMethod : token;
            // save processed payment for charging in next step
            dispatch(
              setTempPaymentInfo({
                amount: total, // TODO: might not need this here, bcuz will need to update redux inside Review step too if user changes plan
                tokenId: tokenDataToUse.id,
                tokenInfo: tokenDataToUse,
                paymentMethod: paymentMethod,
                couponCode: couponCode,
                setErrorMessage: setErrorMessage,
                scrollErrorIntoView: scrollErrorIntoView,
                setCompletePurchase: setCompletePurchase,
                setOrderDetails: setOrderDetails,
                stripe: stripe
              }),
            );
            // save stripe instance for later repopulating of form fields
            dispatch(setStripeInstance(tokenDataToUse));
            dispatch(submitToggleRipspotCreation()); // this var used to be local when review was last step, UX change so payment is now last, just call the code still in review as is for now using redux to toggle the onChangeWatcher...maybe refactor later
          }}
          onHandleError={error => {
            setErrorMessage(error.message);
            scrollErrorIntoView();
            // TODO: Need to handle credit card opayment errors here
          }}
        />
        <ErrorMessage ref={errorRef}>{errorMessage}</ErrorMessage>
      </PriceWrapper>
    </Container>
  );
};

Payment.propTypes = {
  setTempData: PropTypes.func.isRequired,
  getTempData: PropTypes.func.isRequired,
  completePurchase: PropTypes.bool.isRequired,
  setCompletePurchase: PropTypes.func.isRequired,
  setDisableContinue: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  checkout: PropTypes.any.isRequired,
  ripspotIsLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ checkout, stripe, ripspots }) => ({
  checkout,
  stripe,
  ripspotIsLoading: ripspots.ripspotIsLoading,
});
const withConnect = connect(mapStateToProps);
export default compose(withConnect)(Payment);

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 600px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
`;

const PriceWrapper = styled.div`
  margin-bottom: 10px;
  padding-bottom: 3em;
  > p {
    margin: 1.25em 0;
  }
`;

const ErrorMessage = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  color: #9e2146;
  margin: 0.5em 0;
  margin-bottom: 2em;
`;

const StyledPriceBlock = styled(PriceBlock)`
  .price-box {
    padding: 20px;
    transition: all 0.3s ease-in-out;
    border-radius: 3px;
    border: 2px solid transparent;
    &.active {
      border-color: ${({ theme }) => theme.colors.Primary};
      ${({ theme }) => theme.styles.boxShadows[1]};
    }
  }
`;

const BottomPriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 35px;
  p {
    margin-top: 1em;
    strong {
      font-weight: 700;
      color: black;
      font-size: 1.1rem;
    }
  }
`;

const Title = styled.h2`
  font-size: 1.35rem;
  color: black;
  ${({ theme }) => theme.media.min.mobile`
    font-size: 1.6rem;
  `}
`;
