//
// Below is no longer true, this file IS IN USE!
//
// // Officially depracated due to issues with importing
// // not having them at build time?
// // some odd file resolution race condition.
// // use inline in ExploreMap/index.js

// the number keys allow use to transition RTL/LTR by checking a > b || a < b
// for UI logic, check against the step.key String, e.g. steps[1].key === photoGallery
import {
  BIO,
  DETAILS,
  PHOTO_GALLERY,
  REVIEW,
  PAYMENT,
  SHARE,
} from 'utils/constants';
import {
  MAP_CR_DETAILS_ROUTE,
  MAP_CR_BIO_ROUTE,
  MAP_CR_PHOTOS_ROUTE,
  MAP_CR_REVIEW_ROUTE,
  MAP_CR_PAYMENT_ROUTE,
  MAP_CR_CONFIRMATION_ROUTE,
} from 'utils/routes';

export const steps = {
  1: {
    key: DETAILS,
    title: 'A few details.',
    route: MAP_CR_DETAILS_ROUTE,
  },
  2: {
    key: BIO,
    title: 'Write their story.',
    route: MAP_CR_BIO_ROUTE,
  },
  3: {
    key: PHOTO_GALLERY,
    title: 'Share a few photos of them.',
    route: MAP_CR_PHOTOS_ROUTE,
  },
  4: {
    key: REVIEW,
    title: 'Take a moment to review the Ripspot.',
    route: MAP_CR_REVIEW_ROUTE,
  },
  5: {
    key: PAYMENT,
    title: 'Enter your payment details below.',
    route: MAP_CR_PAYMENT_ROUTE,
  },
  6: {
    key: SHARE,
    title: '', // Share Now
    route: MAP_CR_CONFIRMATION_ROUTE,
  },
};

export const editSteps = {
  1: {
    key: DETAILS,
    title: 'A few details.',
  },
  2: {
    key: BIO,
    title: 'Write their story.',
  },
  3: {
    key: PHOTO_GALLERY,
    title: 'Share a few photos of them.',
  },
  4: {
    key: REVIEW,
    title: 'Take a moment to review the Ripspot.',
  },
  //
  // No payment step!!! already paid, just update existing ripspot here!!
  5: {
    key: SHARE,
    title: '', // Share Now
  },
};

//
//
//
export const stepNumByKey = {
  DETAILS_STEP: 1,
  BIO_STEP: 2,
  PHOTO_GALLERY_STEP: 3,
  REVIEW_STEP: 4,
  PAYMENT_STEP: 5,
  SHARE_STEP: 6,
};

//
// NO PAYMENT!
// wait...unless they want to change payment but lets wait for v2!
// add as feature to user dashboard UI
//
export const editStepNumByKey = {
  DETAILS_STEP: 1,
  BIO_STEP: 2,
  PHOTO_GALLERY_STEP: 3,
  REVIEW_STEP: 4,
  SHARE_STEP: 5,
};
