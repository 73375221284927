/**
 *
 * TermsPage
 *
 *
 */

import React, { memo, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'redux';

import Link from 'components/Elements/Link';

import SEO from 'components/seo';
import theme from 'utils/theme';

const TermsPage = () => (
  <ThemeProvider theme={theme}>
    <Container>
      <SEO title="Terms and Conditions" />
      <h1>Terms and Conditions</h1>
      <p>
        <em>Updated: 12/1/2020</em>
      </p>
      <p>
        Ripspot is a digital memorial platform designed to honor the loved ones
        of its users. Our services are designed to provide a lasting tribute to
        the departed, including friends, relatives, or pets of users. We want
        you to share the content you upload and feel free to express yourself,
        but not at the expense or safety of others. You therefore agree to the
        following terms.
      </p>
      <ul>
        <li>
          <a href="#introduction">Introduction</a>
        </li>
        <li>
          <a href="#rights">Right and Limits</a>
        </li>
        <li>
          <a href="#disclaimer">
            Disclaimer, Limit of Liability, and Limit of Access
          </a>
        </li>
      </ul>
      <h2 id="introduction">Introduction</h2>
      <h3>Acceptance of the Terms of Use</h3>
      <p>
        Please read the Terms of Use carefully before you start to use our
        Services. You agree that by clicking to accept or agree to the Terms of
        Use when this option is made available to you, registering, accessing or
        using our Services, you are agreeing to enter into a legally binding
        contract with Ripspot. If you do not agree to this contract (“Contract”
        or “User Agreement”), do not click “Sign Up” (or similar) and do not
        access or otherwise use any of our Services. If you wish to terminate
        this contract, at any time you can do so by closing your account and not
        loner accessing or using our Services.
        <br />
        <br />
        When you use our Services you agree to all of these terms. Your use of
        our Services is also subject to our Privacy Notice and Cookie Notice,
        which covers how we collect, use, share, and store your personal
        information.
        <br />
        <br />
        You are entering into this Contract with Ripspot (also referred to as
        “we” and “us”). This Contract applies to Ripspot.com, Ripspot-branded
        apps, and other Ripspot-related sites, apps, communications and other
        services that state that they are offered under this Contract
        (“Services”).
        <br />
        <br />
        By using our Services, you represent and warrant that you are of legal
        age to form a binding contract with Ripspot.
        <br />
      </p>
      <h3>Changes to the Terms of Use</h3>
      <p>
        Our services may evolve over time, especially as technologies improve or
        become available. Likewise, we may revise and update these Terms of Use,
        Privacy Notice and Cookie Notice from time to time in our sole
        discretion. All changes are effective immediately when we post them and
        apply to all access to and use of the Services. Your continued use of
        the Services following the posting of revised Terms of Use, Privacy
        Notice, and Cookie Notice means that you accept and agree to the
        changes. While we will make the effort to announce changes to our Terms
        of Use to our users, it is your responsibility to adhere to the most
        recent version. You are expected to check this page from time to time so
        you are aware of any changes, as they are binding on you.
      </p>
      <h3>Your Account</h3>
      <p>
        Members are account holders. You agree to (1) use a strong password and
        keep it confidential; (2) not transfer any part of your account and (3)
        follow the law. You are responsible for anything that happened through
        your account unless you close it or report misuse.
      </p>
      <h2 id="rights">Rights and Limits</h2>
      <p>
        We take ownership and intellectual property very seriously. All content
        you upload, create, or share is yours and your responsibility and
        therefore You acknowledge that digital social media platforms are
        designed to share content. When you share your content with others, they
        may in turn share your content with their own networks — without your
        knowledge. For this reason, you own the content you upload and create,
        and you accept responsibility for its accuracy and authenticity.
        <br />
        <br />
        As between you and Ripspot, you own the content and information that you
        submit or post to the Services, and you are only granting Ripspot the
        following non-exclusive license:
        <br />
        <br />A worldwide, transferable and sublicensable right to use, copy,
        modify, distribute, publish and process, information and content that
        you provide through our Services and the services of others, without any
        further consent, notice and/or compensation to you or others. These
        rights are limited in the following ways:
      </p>
      <ul>
        <li>
          <p>
            You can end this license for specific content by deleting such
            content from the Services, or generally by closing your account,
            except (a) to the extent you shared it with others as part of the
            Service and they copied, re-shared it or stored it and (b) for the
            reasonable time it takes to remove from backup and other systems.
          </p>
        </li>
        <li>
          <p>
            While we may edit and make format changes to your content (such as
            modifying the size, layout or file type or removing metadata), we
            will not modify the meaning of your expression.
          </p>
        </li>
      </ul>
      <p>
        You and Ripspot agree that we may access, store, process and use any
        information and personal data that you provide in accordance with, the
        terms of the Privacy Notice.
        <br />
        <br />
        You agree to only provide content or information that does not violate
        the law nor anyone’s rights (including intellectual property rights).
      </p>
      <h3>Sharing of Content</h3>
      <p>
        We have designed our platform with sharing in mind. We allow users to
        freely express themselves — in a respectful manner — paying tribute to
        their loved ones. As such, you adhere to the terms and conditions of not
        just our platform but the platforms you choose to share your Ripspot
        content with, namely Facebook, Twitter, Instagram, and all similar
        social media platforms.
      </p>
      <h3>Intellectual Property Rights</h3>
      <p>
        Ripspot reserves all of its intellectual property rights in the
        Services. Trademarks and logos used in connection with the Services are
        the trademarks of their respective owners. Ripspot and other Ripspot
        trademarks, service marks, graphics and logos used for our Services are
        trademarks or registered trademarks of Ripspot.
      </p>
      <h3>Reporting a Violation</h3>
      <p>
        We ask that you respect others’ intellectual property and copyrights. We
        do not allow the posting of content that violates others’ rights. If you
        feel that your rights or the rights of others have been violated, please
        contact Ripspot and bring such instances to our attention.
        <br />
        <br />
        Likewise, if you find content that is inaccurate or misrepresentative of
        an individual, please bring this to our attention by contacting us. The
        same is true if you find content that is offensive or harmful.
        <br />
        <br />
        We value the freedom of expression but recognize that some content may
        be inappropriate, harmful, or even unlawful. We reserve the right to
        remove such content and report offensive, harmful, or unlawful content
        to the appropriate law enforcement authorities.
        <br />
        <br />
        We work hard to ensure that our users have a free and safe environment
        to pay tribute to their loved ones, and we will take all necessary
        measures to maintain this. Accordingly, we reserve the right to remove a
        user’s account in its entirety without reimbursement should they violate
        these terms and conditions. We do not monitor or moderate the content as
        it is uploaded. As a social media platform, we rely on our users to
        bring violations to our attention, and we make it easy for them to
        contact us.
      </p>
      <h3>Privacy</h3>
      <p>
        You do not need an account to view a Ripspot. All Ripspot pins,
        corresponding names, and profile pictures are viewable by the general
        public.
        <br />
        <br />
        Creating a Ripspot requires the creation of a user account. When you
        create a Ripspot, we ask that your username be the name you use in
        everyday life. We believe this is important for accountability and to
        help ensure the content’s authenticity.
        <br />
        We do not rely on advertising; therefore, we do not make your account
        information and data available to anyone. We work constantly to ensure
        that your user account and information is kept private. Your user
        profile and login credentials are yours alone, and up to you to
        safeguard. You may share your information with anyone you choose, but we
        also make it easy to transfer ownership of your account to a person of
        your choosing.
        <br />
        <br />
        Please refer to our <Link to="/cookies-notice">Privacy Notice</Link> for
        more details on our privacy practices.
      </p>
      <h2 id="disclaimer">
        Disclaimer, Limit of Liability, and Limit of Access
      </h2>
      <h3>No Warranty</h3>
      <p>
        RIPSPOT MAKES NO REPRESENTATION OR WARRANTY ABOUT THE SERVICES,
        INCLUDING ANY REPRESENTATION THAT THE SERVICES WILL BE UNINTERRUPTED OR
        ERROR-FREE, AND PROVIDE THE SERVICES (INCLUDING CONTENT AND INFORMATION)
        ON AN “AS IS” AND “AS AVAILABLE” BASIS. TO THE FULLEST EXTENT PERMITTED
        UNDER APPLICABLE LAW, RIPSPOT DISCLAIMS ANY IMPLIED OR STATUTORY
        WARRANTY, INCLUDING ANY IMPLIED WARRANTY OF TITLE, ACCURACY OF DATA,
        NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.
      </p>
      <h3>Limits on Liabilities</h3>
      <p>
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, RIPSPOT WILL NOT BE
        LIABLE FOR ANY LOST PROFITS, LOSS OF DATA, OR ANY CONSEQUENTIAL,
        PUNITIVE, INCIDENTAL, SPECIAL, OR INDIRECT DAMAGES INCURRED AS THE
        RESULT OF CONTENT ON ITS PLATFORM OR YOUR USE OF THE PLATFORM. ALL
        CONTENT IS THE RESPONSIBILITY OF THE USER WHO CREATED IT.
      </p>
      <h3>Limits on Access</h3>
      <p>
        By becoming a member of Ripspot and setting a Ripspot pin, you
        understand, acknowledge, and agree that you are provided with virtual
        visual access to the location of the pin. You also understand,
        acknowledge, and agree that said virtual visual access does not grant
        you a permission, license, or easement to access the actual physical
        location of the pin. You understand, acknowledge, and agree that pin
        locations may be located on public and/or private land, and that there
        may be no access, limited access, or restricted access to the physical
        location. Should you try to access the physical location of the pin (and
        we direct you not to do so), you understand, acknowledge, and agree that
        you may be subject to local laws of civil and/or criminal trespass. In
        the event that a landowner, municipality, or government entity
        rightfully requests that a pin be removed from a location, we reserve
        the right to remove the pin and notify you of the same. You also
        understand, acknowledge, and agree that the location of a pin may not be
        solely private, as there could be other members of Ripspot that choose
        the same pin location or a pin location adjacent to your pin location.
      </p>
      <h3>Indemnification</h3>
      <p>
        You agree to defend, indemnify, and hold harmless Ripspot, its
        affiliates, licensors, and service providers, and its and their
        respective officers, directors, employees, contractors, agents,
        licensors, suppliers, successors, and assigns from and against any
        claims, liabilities, damages, judgments, awards, losses, costs,
        expenses, or fees (including reasonable attorneys' fees) arising out of
        or relating to your violation of these Terms of Use or your use of the
        Services, including, but not limited to, your User Contributions, any
        use of the Ripspot's content, services, and products other than as
        expressly authorized in these Terms of Use, or your use of any
        information obtained from the Services.
      </p>
      <h3>Governing Law and Jurisdiction</h3>
      <p>
        All matters relating to the Services and these Terms of Use, and any
        dispute or claim arising therefrom or related thereto (in each case,
        including non-contractual disputes or claims), shall be governed by and
        construed in accordance with the internal laws of the State of Maine
        without giving effect to any choice or conflict of law provision or rule
        (whether of the State of Maine or any other jurisdiction).
        <br />
        <br />
        Any legal suit, action, or proceeding arising out of, or related to,
        these Terms of Use or the Service shall be instituted exclusively in the
        federal courts of the United States or the courts of the State of Maine.
        You waive any and all objections to the exercise of jurisdiction over
        you by such courts and to venue in such courts.
      </p>
      <h3>Arbitration</h3>
      <p>
        At Ripstop's sole discretion, it may require you to submit any disputes
        arising from these Terms of Use or use of the Services, including
        disputes arising from or concerning their interpretation, violation,
        invalidity, non-performance, or termination, to final and binding
        arbitration under the Rules of Arbitration of the American Arbitration
        Association applying Maine law.
      </p>
      <h3>Waiver and Severability</h3>
      <p>
        No waiver by Ripspot of any term or condition set out in these Terms of
        Use shall be deemed a further or continuing waiver of such term or
        condition or a waiver of any other term or condition, and any failure of
        Ripspot to assert a right or provision under these Terms of Use shall
        not constitute a waiver of such right or provision.
        <br />
        <br />
        If any provision of these Terms of Use is held by a court or other
        tribunal of competent jurisdiction to be invalid, illegal, or
        unenforceable for any reason, such provision shall be eliminated or
        limited to the minimum extent such that the remaining provisions of the
        Terms of Use will continue in full force and effect.
      </p>
      <h3>Cancelation & Refund</h3>
      <p>
        The creation of a Ripspot and the subsequent publishing and sharing of
        it is a Service rendered. If you are not satisfied with our Service, you
        may contact Ripspot to inquire about a Refund within 30 days of
        Publishing the Ripspot in question. A Ripspot will automatically renew
        annually, beginning a year after its initial publication. We will notify
        you in advance of renewal. You may cancel at any time. You may cancel at
        anytime by emailing us at{' '}
        <a href="mailto:support@ripspot.com?subject=Refund Request">
          support@ripspot.com
        </a>
        .
      </p>
      <h3>Entire Agreement</h3>
      <p>
        These Terms of Use constitute the sole and entire agreement between you
        and Ripspot regarding the Services and supersede all prior and
        contemporaneous understandings, agreements, representations, and
        warranties, both written and oral, regarding the Services.
      </p>
    </Container>
  </ThemeProvider>
);
TermsPage.propTypes = {};

// const mapStateToProps =  props => ({});
const withConnect = connect();
export default compose(memo, withConnect)(TermsPage);

const Container = styled.div`
  color: ${theme.colors.Primary};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${({ theme }) => theme.styles.middle}
  max-width: 680px;
  margin: 0 auto;
  padding: 2em 1em;
  padding-bottom: 10em;
  h1 {
    color: black;
    font-size: 4.2em;
    margin-top: 2em;
  }
  h2 {
    color: black;
    margin-top: 2em;
    align-self: flex-start;
  }
  h3 {
    font-size: 2em;
    margin-top: 1em;
    color: black;
    align-self: flex-start;
  }
  p {
    font-size: 1.7em;
    color: black;
    margin: 1em 0;
    align-self: flex-start;
    &:first-of-type {
      margin-top: 2.5em;
    }
  }
  a {
    text-decoration: underline;
  }
  ul {
    align-self: flex-start;
    padding-left: 3em;
    list-style: disc;
    li {
      font-size: 1.7em;
      color: black;
      margin: 0.5em 0;
      font-family: 'Montserrat', sans-serif;
      line-height: 1.5em;
      p {
        font-size: inherit;
        margin: 0 !important;
      }
    }
  }
`;
